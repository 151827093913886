import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Stack, Alert, Badge } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../constant";
import { useDispatch, useSelector } from "react-redux";
const CommondityEdit = () => {
  const CHARACTER_LIMIT = 1;
  const [boxno, setboxno] = useState("");
  const [dispBoxNo, setDispBoxNo] = useState("");
  const [dispQty, setDispQty] = useState(0);
  const [msg, setMsg] = useState("");
  const [msgN, setMsgN] = useState(false);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  console.log("==------==>", location.state);
  const EditCommodityApi = async () => {
    const resParkingList = await axios.post(
      `${baseUrl}/commodityparkinglists/qtysubs`,
      {
        comlstparkingid: location.state.comlstparkingid,
        qty: dispQty,
      }
    );
    if (resParkingList.status === 200) {
      const res = await axios.post(`${baseUrl}/commodityorderlists/dispqty`, {
        dispatchqty: dispQty,
        dispatchboxno: dispBoxNo,
        comlstorderid: location.state.comlstorderid,
      });
      if (res.status === 200) {
        setMsg("Successfully updated the inventory.");
        navigate("/commoditylist");
      } else {
        const resParkingListRevert = await axios.post(
          `${baseUrl}/commodityparkinglists/qtyadd`,
          {
            comlstparkingid: location.state.comlstparkingid,
            qty: dispQty,
          }
        );
        if (resParkingListRevert.status === 200) {
          setMsg("Successfully reverted the stock.");
        }
      }
    }
  };
  const handleSubmit = () => {
    EditCommodityApi();
  };
  const handleCancel = () => {
    navigate("/commoditylist");
  };
  useEffect(() => {
    setToggle(dispBoxNo === "" || dispQty === "");
  }, [dispBoxNo, dispQty]);
  useEffect(() => {
    const tkn = localStorage.getItem("token");
    !tkn && navigate("/Login");
  }, []);
  return (
    <div className="container">
      <h3>{location.state.selectedComd.comlstinfo[0].nameofproduct}</h3>
      <h4>{location.state.selectedComd.comlstinfo[0].componentno}</h4>
      <br />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Dispatch Box Number"
            fullWidth
            value={location.state.boxno}
          />
        </Grid>

        <Grid item xs={12}>
          <Button variant="outlined">{`Pending Quantity: ${location.state.qtypending}`}</Button>
        </Grid>

        <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              onChange={(e) => setDispQty(e.target.value)}
              label="Dispatch Qty"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: "10px" }}>
              <b>{`${dispBoxNo}`}</b>
            </h3>
            <TextField
              variant="outlined"
              type="number"
              onChange={(e) =>
                setDispBoxNo(
                  `${location.state.subsinfo.companyname}-${e.target.value}`
                )
              }
              fullWidth
              label="Dispatch Box Number"
            />
          </Grid>
        </Grid>

        <Grid item xl={4} lg={2} md={12} sm={12} xs={12}>
          <Button
            fullWidth
            variant="contained"
            disabled={toggle}
            onClick={handleSubmit}
          >
            Sumbit
          </Button>
        </Grid>
        <Grid item xl={4} lg={1.5} md={12} sm={12} xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <Stack sx={{ width: "100%" }} spacing={2}></Stack>
        </Grid>
        <Stack sx={{ width: "100%" }} spacing={2}></Stack>
      </Grid>
    </div>
  );
};

export default CommondityEdit;

import React from "react";
import {
  Button,
  Grid,
  TextField,
  Card,
  CardContent,
  Badge,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { baseUrl } from "../constant";
import { useNavigate } from "react-router-dom";

const ProductParking = () => {
  const navigate = useNavigate();
  const CHARACTER_LIMIT = 1;
  const [bay, setBay] = useState("");
  const [num, setNum] = useState("");
  const [show, setShow] = useState("");
  const [display, setDisplay] = useState(false);
  const [showNo, setShowNo] = useState("");
  const [toggle, setToggle] = useState(false);
  const [msg, setMsg] = useState(false);
  const [msgN, setMsgN] = useState(false);
  const [txtSucces, setTxtSucces] = useState("");
  const [boxNo, setBoxNo] = useState("");
  const [parkedBox, setParkedBox] = useState(0);
  const [parkingBox, setParkingBox] = useState(0);
  const [subs, setSubs] = useState([]);
  const [subsFilt, setSubsFilt] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const handleSumbit = async () => {
    const url = `${baseUrl}/parkingallotment/add`;
    const payload = {
      parkbay: bay,
      parkno: num,
      boxno: selectedCustomer,
    };

    const result = await axios.post(url, payload);
    setTxtSucces(result.data);
    setSubsFilt([]);
    setNum("");
    setBoxNo("");
    setBay("");
  };

  const handleCancel = () => {
    setNum("");
    setBoxNo("");
    setBay("");
    setTxtSucces("");
  };

  const allConditions = () => {
    if (bay.length > 1) {
      setMsg(true);
      setShow("Enter Single Letter Eg. A Or B  ");
      if (!/^[a-zA-Z]*$/g.test(bay)) {
        setShow("Enter Valid Letter");
        setMsg(true);
      }
    } else {
      setMsg(false);
    }
    if (num < 0) {
      setMsgN(true);
      setShowNo("Minum limit is 1 ");
    } else {
      setMsgN(false);
    }
    if (num > 25) {
      setMsgN(true);
      setShowNo("Max limit is 25");
    }
    if (bay.length > 1 && bay.length < 1 && bay !== "") {
      setMsg(true);
      setShow("done");
    }
  };
  const getSubs = async () => {
    const result = await axios.get(`${baseUrl}/commodityparkinglists/all`);
    const unique = [...new Set(result.data.map((item) => `${item.boxno}`))];

    const result2 = await axios.get(`${baseUrl}/parkingallotment/all`);
    const unique2 = [...new Set(result2.data.map((item) => `${item.boxno}`))];
    setParkedBox(unique2.length);
    const resData = unique.filter((n) => !unique2.includes(n));
    setParkingBox(resData.length);
    setSubs(resData);
  };
  useEffect(() => {
    const inputNum = num >= 1 && num <= 25 && boxNo.length > 11 ? true : false;
    // const bayConditon = !/^[a-zA-Z]*$/g.test(bay);
    setToggle(bay.length !== 1 || selectedCustomer === "");
    allConditions();
  }, [bay, num, show, boxNo]);

  useEffect(() => {
    if (boxNo.trim() !== "") {
      const result = subs.filter((item) =>
        `${item}`.toUpperCase().includes(boxNo.toUpperCase())
      );
      setSubsFilt(result);
    }
  }, [boxNo]);

  useEffect(() => {
    setDisplay(true);
    setTimeout(() => {
      setDisplay(false);
      setSelectedCustomer("");
      getSubs();
    }, 1500);
  }, [txtSucces]);

  useEffect(() => {
    const tkn = localStorage.getItem("token");
    !tkn && navigate("/Login");
    getSubs();
  }, []);
  const handleDetails = () => {
    navigate("/parkDetail");
  };
  return (
    <div className="container">
      {display ? (
        <Alert severity="success">{txtSucces}</Alert>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={7}>
            <Badge badgeContent={`Parked`} color="success">
              <Card className="container" fullWidth onClick={handleDetails}>
                <CardContent>
                  <h3>{parkedBox}</h3>
                </CardContent>
              </Card>
            </Badge>
          </Grid>
          <Grid item xs={5}>
            <Badge badgeContent={`Parking`} color="warning">
              <Card className="container" fullWidth>
                <CardContent>
                  <h3>{parkingBox}</h3>
                </CardContent>
              </Card>
            </Badge>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={3}>
        {selectedCustomer.length === 0 ? (
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Enter Box Number"
              fullWidth
              onChange={(e) => setBoxNo(e.target.value)}
            />
            <Grid container spacing={1}>
              {subsFilt &&
                subsFilt.map((item) => (
                  <Grid item xs={12}>
                    <Button
                      variant="outlined"
                      fullWidth
                      onClick={() => setSelectedCustomer(item)}
                    >
                      {item}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
            {selectedCustomer.length > 0 && (
              <Button
                variant="outlined"
                fullWidth
                onClick={() => setSelectedCustomer("")}
              >
                {selectedCustomer}
              </Button>
            )}
          </Grid>
        )}

        <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            color={msg == true ? "error" : ""}
            label="Park Bay"
            inputProps={{
              maxlength: CHARACTER_LIMIT,
            }}
            helperText={`${bay.length}/${CHARACTER_LIMIT}`}
            value={bay}
            variant="outlined"
            onChange={(e) => setBay(e.target.value.toUpperCase())}
          />
        </Grid>
        <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
          <TextField
            color={msgN == true ? "error" : ""}
            value={num}
            inputProps={{
              max: 25,
            }}
            helperText={`${num.toString().length}/${CHARACTER_LIMIT + 1}`}
            fullWidth
            type="number"
            label="Park No"
            variant="outlined"
            onChange={(e) => setNum(Number(e.target.value))}
          />
        </Grid>
        <Grid item xl={4} lg={2} md={12} sm={12} xs={12}>
          <Button
            fullWidth
            disabled={toggle}
            variant="contained"
            onClick={handleSumbit}
          >
            Sumbit
          </Button>
        </Grid>
        <Grid item xl={4} lg={1.5} md={12} sm={12} xs={12}>
          <Button fullWidth variant="contained" onClick={handleCancel}>
            Cancel
          </Button>
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            {msgN == true ? <Alert severity="error">{showNo}</Alert> : ""}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductParking;

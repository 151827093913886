import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CommodityList from "./CommodityList";
import CommodityLogin from "./CommodityLogin";
import Home from "./Home";

import TopBarNavigation from "./Navi/TopBarNavigation";
import ProductParking from "./ProductParking";
import ParkDetails from "../pages/ParkDetails/ParkDetails";
import EditPark from "../pages/ParkDetails/EditPark";
import CommondityEdit from "./CommodityEdit";
import DispatchDetails from "./DispatchDetails";
import CommoditySetting from "../ManageCommodity/CommoditySetting";
import NewAddCommodity from "../ManageCommodity/NewAddCommodity";

const Landing = () => {
  return (
    <div>
      <BrowserRouter>
        <TopBarNavigation />
        <Routes>
          <Route path="/" element={<ProductParking />} />
          <Route path="/home" element={<Home />} />
          <Route path="/productparking" element={<ProductParking />} />
          <Route path="/commoditylist" element={<CommodityList />} />
          <Route path="/Login" element={<CommodityLogin />} />
          <Route path="/parkDetail" element={<ParkDetails />} />
          <Route path="/EditPark" element={<EditPark />} />
          <Route path="/commondityEdit" element={<CommondityEdit />} />
          <Route path="/dispatchDetails" element={<DispatchDetails />} />
          <Route path="/commoditysetting" element={<CommoditySetting />} />
          <Route path="/newaddcommodity" element={<NewAddCommodity />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Landing;

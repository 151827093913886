import React from "react";
import Landing from "./Components/Landing";
import "./Components/common.css";
import CommoditySetting from "./ManageCommodity/CommoditySetting";


const App = () => {
  return(


    <Landing />

    
  )
};

export default App;

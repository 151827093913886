import React from "react";
import { TableCell, TableRow } from "@mui/material";
const CommodityItem = ({ elem, handleComlstid, selectedComd }) => {
  return (
    <React.Fragment>
      {elem.qty - elem.dispatchqty !== 0 && elem.isActive && (
        <>
          <TableRow
            onClick={() => handleComlstid(elem)}
            className={
              selectedComd.comlstid === elem.comlstinfo[0].comlstid &&
              "content-comm-item"
            }
          >
            <TableCell align="center">
              {elem.comlstinfo[0].nameofproduct.split("").slice(0, 30).join("")}
              {elem.comlstinfo[0].nameofproduct.length > 30 && "..."}
            </TableCell>
            <TableCell align="left">{elem.qty - elem.dispatchqty}</TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  );
};

export default CommodityItem;

import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, Stack, Alert } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../../constant";
const EditPark = () => {
  const CHARACTER_LIMIT = 1;
  const [bay, setBay] = useState("");
  const [num, setNum] = useState("");
  const [show, setShow] = useState("");
  const [showNo, setShowNo] = useState("");
  const [msg, setMsg] = useState(false);
  const [msgN, setMsgN] = useState(false);
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const allConditions = () => {
    if (bay.length > 1) {
      setMsg(true);
      setShow("Enter Single Letter Eg. A Or B  ");
      if (!/^[a-zA-Z]*$/g.test(bay)) {
        setShow("Enter Valid Letter");
        setMsg(true);
      }
    } else {
      setMsg(false);
    }
    if (num < 0) {
      setMsgN(true);
      setShowNo("Minum limit is 1 ");
    } else {
      setMsgN(false);
    }
    if (num > 25) {
      setMsgN(true);
      setShowNo("Max limit is 25");
    }
    if (bay.length > 1 && bay.length < 1 && bay !== "") {
      setMsg(true);
      setShow("done");
    }
  };
  const handleback = () => {
    navigate("/parkDetail");
  };
  const EditParkApi = async () => {
    const res = await axios.post(`${baseUrl}/parkingallotment/reparking`, {
      parkingallotmentid: location.state.parkingallotmentid,
      parkbay: bay,
      parkno: num,
    });
    if (res.status == 200) {
      navigate("/parkDetail");
    }
  };
  const handleSubmit = () => {
    EditParkApi();
  };
  useEffect(() => {
    setToggle(bay.length !== 1);
    allConditions();
  }, [bay, num]);
  useEffect(() => {
    const tkn = localStorage.getItem("token");
    !tkn && navigate("/Login");
  }, []);
  return (
    <div className="container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            fullWidth
            value={location.state.boxno}
          />
        </Grid>

        <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            color={msg == true ? "error" : ""}
            label="Park Bay"
            inputProps={{
              maxlength: CHARACTER_LIMIT,
            }}
            helperText={`${bay.length}/${CHARACTER_LIMIT}`}
            value={bay}
            variant="outlined"
            onChange={(e) => setBay(e.target.value.toUpperCase())}
          />
        </Grid>
        <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
          <TextField
            color={msgN == true ? "error" : ""}
            value={num}
            inputProps={{
              max: 25,
            }}
            helperText={`${num.toString().length}/${CHARACTER_LIMIT + 1}`}
            fullWidth
            type="number"
            label="Park No"
            variant="outlined"
            onChange={(e) => setNum(Number(e.target.value))}
          />
        </Grid>
        <Grid item xl={4} lg={2} md={12} sm={12} xs={12}>
          <Button
            fullWidth
            variant="contained"
            disabled={toggle}
            onClick={handleSubmit}
          >
            Sumbit
          </Button>
        </Grid>
        <Grid item xl={4} lg={1.5} md={12} sm={12} xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={handleback}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xl={4} lg={6} md={6} sm={12} xs={12}>
          <Stack sx={{ width: "100%" }} spacing={2}></Stack>
        </Grid>
        <Stack sx={{ width: "100%" }} spacing={2}>
          {msgN == true ? <Alert severity="error">{showNo}</Alert> : ""}
        </Stack>
      </Grid>
    </div>
  );
};

export default EditPark;

import React, { useEffect, useState } from "react";
import { Button, Switch, TableCell, TableRow, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../constant";
const CommodityItemEdit = ({ elem, handleComlstid, selectedComd, ind }) => {
  const [checked, setChecked] = useState(true);
  const [commodityQty, setCommodityQty] = useState("");
  const [btnToggle, setbtnToggle] = useState(false);

  const editShow = useSelector((state) => state.commonReducer.editOpt);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const updateCommodity = async (item, ind) => {
    console.log("====>", item.comlstorderid);

    const payload = {
      comlstorderid: item.comlstorderid,
      qty: commodityQty,
      isActive: checked,
    };
    await axios.post(
      `${baseUrl}/commodityorderlists/qtyaddwithactive`,
      payload
    );
    setCommodityQty("");
    setChecked(true);
  };

  useEffect(() => {
    !checked && setCommodityQty(-elem.qty);
  }, [checked]);
  return (
    <React.Fragment>
      {elem.qty - elem.dispatchqty !== 0 && elem.isActive && (
        <>
          <TableRow
            className={
              selectedComd.comlstid === elem.comlstinfo[0].comlstid &&
              "content-comm-item"
            }
          >
            <TableCell align="center">
              {elem.comlstinfo[0].nameofproduct.split("").slice(0, 30).join("")}
              {elem.comlstinfo[0].nameofproduct.length > 30 && "..."}
            </TableCell>
            <TableCell align="left">{elem.qty - elem.dispatchqty}</TableCell>
            <TableCell>
              {editShow === true && (
                <TextField
                  value={commodityQty}
                  type="number"
                  fullWidth
                  onChange={(e) => setCommodityQty(Number(e.target.value))}
                />
              )}
            </TableCell>
            <TableCell align="left">
              {editShow === true && (
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              )}
            </TableCell>
            <TableCell align="left">
              {editShow === true && (
                <Button
                  variant="contained"
                  className="layout_clr"
                  onClick={() => updateCommodity(elem, ind)}
                  disabled={btnToggle}
                >
                  save
                </Button>
              )}
            </TableCell>
          </TableRow>
        </>
      )}
    </React.Fragment>
  );
};

export default CommodityItemEdit;

export const commonReducer = (
  state = { parkingDetails: [], editOpt: true },
  action
) => {
  if (action.type == "PARKING_DETAILS") {
    return {
      ...state,
      parkingDetails: action.data,
    };
  }
  if (action.type === "EDIT_OPT") {
    return {
      ...state,
      editOpt: action.data,
    };
  }
  return state;
};

import React from "react";
import { Grid } from "@mui/material";

const LogoImg = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={3} sm={2} xs={3}></Grid>
        <Grid item xl={4} lg={8} md={6} sm={8} xs={6}>
          <img
            className="content_center"
            width="250px"
            src="2222-removebg-preview.png"
          />
        </Grid>
        <Grid item xl={4} lg={2} md={3} sm={2} xs={3}></Grid>
      </Grid>
    </div>
  );
};

export default LogoImg;

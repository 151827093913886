import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import LogoImg from "./LogoImg";
import MobileAutoComp from "./AutoComp";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const tkn = localStorage.getItem("token");
    !tkn && navigate("/Login");
  }, []);
  return (
    <div className="container">
      <Grid container spacing={2}>
        <Grid item xl={4} lg={3} md={3} sm={2} xs={3}></Grid>
        <Grid item xl={4} lg={5} md={3} sm={2} xs={3} className="logo">
          <LogoImg />
        </Grid>
        <Grid item xl={4} lg={4} md={3} sm={1} xs={3}></Grid>
        <Grid container spacing={1}>
          <Grid item xl={4} lg={4} md={3} sm={2} xs={3}></Grid>
          <Grid
            item
            xl={4}
            lg={6}
            md={6}
            sm={8}
            xs={6}
            className="mobile_contaien"
          >
            <MobileAutoComp className="mobile-auto-comp" />
          </Grid>
          <Grid item xl={4} lg={4} md={3} sm={1} xs={3}></Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default Home;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../../constant";
import { Grid, Card, CardContent, TextField, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
const ParkDetails = () => {
  const [parkData, setParkData] = useState([]);
  const [copyParkData, setCopyData] = useState([]);
  const [boxNo, setBoxNo] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const select = useSelector((state) => state.commonReducer);
  const getSubs = async () => {
    if (select.parkingDetails.length == 0) {
      const result = await axios.get(`${baseUrl}/parkingallotment/all`);
      setCopyData(result.data);
      setParkData(result.data);
      if (result.status == 200) {
        dispatch({
          type: "PARKING_DETAILS",
          data: result.data,
        });
      }
    }
  };
  useEffect(() => {
    getSubs();
  }, []);

  useEffect(() => {
    if (boxNo.trim() !== "") {
      const result = copyParkData.filter((item) =>
        item.boxno.toUpperCase().includes(boxNo.toUpperCase())
      );
      setParkData(result);
    }
  }, [boxNo]);
  const handleEdit = (item) => {
    navigate("/EditPark", { state: item });
  };
  return (
    <div className="container">
      <Grid container spacing={4}>
        <Grid item xl={4} lg={3.5} md={12} sm={12} xs={12}>
          <TextField
            variant="outlined"
            label="Search Box"
            fullWidth
            onChange={(e) => setBoxNo(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        {parkData?.map((item) => {
          return (
            <Grid item xs={12} md={4}>
              <Card style={{ width: "400px" }}>
                <CardContent>
                  <h4 style={{ margin: "4px" }}>BOX NO : {item.boxno}</h4>
                  <h4 style={{ margin: "4px" }}> Park Bay : {item.parkbay}</h4>
                  <h4 style={{ margin: "4px" }}> Park NO : {item.parkno}</h4>
                  <Button
                    variant="contained"
                    style={{ position: "relative", left: "250px" }}
                    onClick={() => handleEdit(item)}
                  >
                    Edit
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default ParkDetails;

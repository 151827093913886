import React, { useState, useEffect } from "react";
import { TextField, Grid } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { baseUrl } from "../constant";
export default function MobileAutoComp() {
  const [user, setUser] = useState([]);

  const getUserData = async () => {
    const res = await axios.get(`${baseUrl}/subscriber/all`);
    setUser(res.data);
  };
  useEffect(() => {
    getUserData();
  }, []);

  // convert materuil ui paramitors
  const getOptionDropdwonData = (optionList) => {
    const options = optionList.map((item) => {
      return {
        label: item["mobile"] + " " + "-" + " " + item["city"],
        key: item["subsid"],
      };
    });
    return [...options];
  };

  return (
    <>
      <Autocomplete
        fullWidth
        disablePortal
        id="combo-box-demo"
        options={getOptionDropdwonData(user)}
        sx={{ width: 400 }}
        renderInput={(params) => (
          <TextField fullWidth {...params} label="Mobile" />
        )}
      />
    </>
  );
}

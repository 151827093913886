import React, { useState } from "react";
import axios from "axios";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
} from "@mui/material";
import { baseUrl } from "../constant";
import { useNavigate } from "react-router-dom";

const CommodityLogin = () => {
  const navigate = useNavigate();
  const [userid, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleLoginApi = async (item) => {
    const url = `${baseUrl}/subscriber/auth`;
    const payload = { userid, password };
    const result = await axios.post(url, payload);
    setToken(result.data);

    window.localStorage.setItem("token", result.data.token);
    const tkn = localStorage.getItem("token");
    tkn ? navigate("/") : navigate("/Login");
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={10} sm={12} xs={12}></Grid>
        <Grid item xl={4} lg={4} md={10} sm={12} xs={12}>
          <Card className="container">
            <CardContent>
              <Grid container spacing={3}>
                <Grid
                  className="logo"
                  item
                  xl={4}
                  lg={12}
                  md={10}
                  sm={12}
                  xs={12}
                >
                  <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={8}>
                      <img width="200px" src="2222-removebg-preview.png" />
                    </Grid>
                    <Grid item xs={2}></Grid>
                  </Grid>
                </Grid>
                <Grid item xl={4} lg={12} md={10} sm={12} xs={12}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label="User ID"
                    onChange={(e) => setUserId(e.target.value)}
                  />
                </Grid>
                <Grid item xl={4} lg={12} md={10} sm={12} xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      value={values.password}
                      onChange={handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                </Grid>
                <Grid item xl={4} lg={12} md={10} sm={12} xs={12}>
                  <Button
                    fullWidth
                    style={{backgroundColor:"#2d3192"}}
                    variant="contained"
                    onClick={handleLoginApi}
                  >
                    Login
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CommodityLogin;

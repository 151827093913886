import React, { useState, useEffect } from "react";
import { Button, Grid, TextField } from "@mui/material";
import { baseUrl } from ".././constant";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const NewAddCommodity = () => {
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [subs, setSubs] = useState([]);
  const [txt, setTxt] = useState("");
  const [commotxt, setCommoTxt] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedCommodity, setSelectedCommodity] = useState([]);
  const [subsFilt, setSubsFilt] = useState([]);
  const [getAllCommody, setGetAllCommody] = useState([]);
  const [allCommodyFilt, setAllCommodyFilt] = useState([]);
  const navigate = useNavigate();
  const handleSubmite = async () => {
    const url = `${baseUrl}/commodityorderlists/add`;
    const Payload = {
      subsid: selectedCustomer[0].subsid,
      comlstid: selectedCommodity[0].comlstid,
      qty: quantity,
      price: price,
    };
    const result = await axios.post(url, Payload);
    console.log("submiteResponse", result);
  };

  const getAllCommodity = async () => {
    const result = await axios.get(`${baseUrl}/commoditylists/all`);
    setGetAllCommody(result.data);
  };

  useEffect(() => {
    getAllCommodity();
  }, []);

  const getSubs = async () => {
    const result = await axios.get(`${baseUrl}/subscriber/all`);
    setSubs(result.data);
  };
  useEffect(() => {
    getSubs();
  }, []);
  const handleCancel = () => {
    navigate("/commoditysetting");
  };
  useEffect(() => {
    const result = subs.filter(
      (item) =>
        item.companyname.toUpperCase().includes(txt.toUpperCase()) ||
        `${item.mobile}`.includes(txt)
    );
    setSubsFilt(result);
  }, [txt]);

  useEffect(() => {
    if (commotxt.length > 2) {
      const result = getAllCommody.filter(
        (item) =>
          item.nameofproduct.toUpperCase().includes(commotxt.toUpperCase()) ||
          `${item.oldspecification}`.includes(commotxt)
      );
      setAllCommodyFilt(result);
    }
  }, [commotxt]);
  return (
    <div className="container">
      <Grid container spacing={2}>
        {selectedCustomer.length === 0 ? (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant={"outlined"}
                label="Customer"
                fullWidth
                onChange={(e) => setTxt(e.target.value)}
              />
              <Grid container spacing={1}>
                {subsFilt &&
                  subsFilt.map((item, index) => (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => setSelectedCustomer([item])}
                      >
                        {item.mobile} - {item.companyname}
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {selectedCustomer.length > 0 && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setSelectedCustomer([])}
                >
                  {selectedCustomer[0].mobile} -{" "}
                  {selectedCustomer[0].companyname}
                </Button>
              )}
            </Grid>
          </>
        )}

        {selectedCommodity.length === 0 ? (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant={"outlined"}
                label="Commodity List"
                fullWidth
                onChange={(e) => setCommoTxt(e.target.value)}
              />
              <Grid container spacing={1}>
                {allCommodyFilt &&
                  allCommodyFilt.map((item, index) => (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => setSelectedCommodity([item])}
                      >
                        {item.nameofproduct} - {item.oldspecification} -{" "}
                        {item.componentno}
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              {selectedCommodity.length > 0 && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setSelectedCommodity([])}
                >
                  {selectedCommodity[0].nameofproduct} -{" "}
                  {selectedCommodity[0].newspecification}
                </Button>
              )}
            </Grid>
          </>
        )}

        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Quantity"
              type="number"
              onChange={(e) => setQuantity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Price"
              type="number"
              fullWidth
              onChange={(e) => setPrice(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth onClick={handleSubmite} variant="contained">
              Submit
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="error"
              variant="contained"
              onClick={handleCancel}
            >
              Cancel{" "}
            </Button>
          </Grid>
        </>
      </Grid>
    </div>
  );
};

export default NewAddCommodity;

import React, { useState, useEffect } from "react";
import { Grid, Button, TextField, CardContent } from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { baseUrl } from "../constant";
import {
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Card,
} from "@mui/material";
import { Audio } from "react-loader-spinner";

const DispatchDetails = () => {
  const [allData, setallData] = useState([]);
  const location = useLocation();
  const [isPrint, setIsPrint] = useState(false);
  const [isStcker, setIsSticker] = useState(false);
  const getFilterData = async () => {
    const res2 = await axios.post(
      `${baseUrl}/commodityorderlists/filterboxes`,
      {
        subsid: location.state[0].subsid,
      }
    );

    const filtOnly = res2.data.filter(
      (item) => item.data[0].subsid === location.state[0].subsid
    );
    const printData = [];
    filtOnly.forEach((element) => {
      const boxno = element._id;
      const itemsInBox = [];
      element.comlstinfo.map((comitem) => {
        const comlstid = comitem.comlstid;
        const nameofproduct = comitem.nameofproduct;
        const newspecification = comitem.newspecification;
        const filtData = element.data.filter(
          (item) => item.comlstid === comlstid
        );
        const qty = filtData[0].qty;
        const itemsObj = {
          boxno,
          comlstid,
          nameofproduct,
          qty,
          newspecification,
        };
        itemsInBox.push(itemsObj);
      });
      printData.push(itemsInBox);
      // const qty = filtData[0].qty;
      // const objBox = {
      //   boxno,
      //   comlstid,
      //   nameofproduct,
      //   newspecification,
      //   // qty,
      // };
      // console.log(objBox);
      // printData.push(objBox);
    });
    console.log(printData);
    setIsSticker(true);
    setallData(printData);
  };
  useEffect(() => {
    getFilterData();
  }, []);

  const handlePrint = () => {
    setIsPrint(true);
    window.print();
  };

  return (
    <div className="container" >
      <>
        {!isPrint && (
          <Grid item md={2} id="non-printable">
            <Button
              onClick={handlePrint}
              variant="contained"
              style={{ bottom: "20px" }}
            >
              Print
            </Button>
          </Grid>
        )}
        {isStcker ? (
          <React.Fragment>
            <Grid container spacing={1}>
              {allData.length > 0 &&
                allData.map((filteredData) => (
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <React.Fragment>
                      <Card sx={{ m: 1 }}>
                        <CardContent>
                          <Grid container spacing={0}>
                            <Grid
                              className="content-dispatch-company"
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                            >
                              <div>
                                <h3>{location.state[0].companyname}</h3>
                                <h5>{location.state[0].addressline} </h5>
                                <span>{location.state[0].city}</span> -
                                <span>{location.state[0].statename}</span>
                                <h4>Box Number : {filteredData[0].boxno}</h4>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className="contentdata"
                            >
                              <TableContainer>
                                <Table aria-label="a dense table">
                                  <TableHead
                                    style={{ backgroundColor: "#e5e5e5" }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        className="content-list-table-printing"
                                        align="left"
                                      >
                                        Product Name
                                      </TableCell>
                                      <TableCell
                                        className="content-list-table-printing"
                                        align="left"
                                      >
                                        Quantity
                                      </TableCell>
                                      <TableCell
                                        className="content-list-table-printing"
                                        align="left"
                                      >
                                        Model Number
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {filteredData.length > 0 &&
                                      filteredData.map((filtItem, index) => (
                                        <TableRow key={index}>
                                          <TableCell align="left">
                                            {filtItem.nameofproduct}
                                          </TableCell>
                                          <TableCell align="left">
                                            {filtItem.qty}
                                          </TableCell>

                                          <TableCell align="left">
                                            {filtItem.newspecification}
                                          </TableCell>
                                        </TableRow>
                                      ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </React.Fragment>
                  </Grid>
                ))}
            </Grid>
          </React.Fragment>
        ) : (
          <div className="content-loader printable">
            <Audio
              height="80"
              width="80"
              radius="9"
              color="#2d3192"
              ariaLabel="three-dots-loading"
              wrapperStyle
              wrapperClass
            />
          </div>
        )}
      </>
    </div>
  );
};

export default DispatchDetails;

import React, { useState, useEffect } from "react";
import {
  Button,
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Paper,
  Grid,
  Alert,
} from "@mui/material";
import axios from "axios";
import CommodityItem from "./CommodityItem";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../constant";
import { blue } from "@mui/material/colors";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch } from "react-redux";

const CommodityList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [boxes, setBoxes] = useState([]);
  const [txt, setTxt] = useState("");
  const [park, setPark] = useState([]);
  const [subs, setSubs] = useState([]);
  const [subsFilt, setSubsFilt] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [Id, setId] = useState();
  const [vali, setVali] = useState();
  const [selectedComd, setSelectedComd] = useState([]);
  const [error, setError] = useState("");
  const [status, setStatus] = useState("");
  const handleComlstid = async (item) => {
    if (Object.keys(item).length > 0) {
      setSelectedComd(item);
      const url = `${baseUrl}/commodityparkinglists/filterwithparked`;
      const payload = { comlstid: item.comlstid };
      const result = await axios.post(url, payload);
      setBoxes(result.data);
    } else {
      setError("Please Park the item before dispatching.");
    }
  };

  const handleNavigate = () => {
    console.log("function calling");
    navigate("/commoditysetting");
    dispatch({ type: "EDIT_OPT", data: true });
  };

  const handleClr = (i) => {
    setId(i);
    setVali(vali === true ? false : true);
  };

  const getParkedData = async (item) => {
    const newItem = {
      comlstorderid: selectedComd.comlstorderid,
      qtypending: selectedComd.qty - selectedComd.dispatchqty,
      ...item,
      subsinfo: selectedCustomer[0],
      selectedComd: selectedComd,
    };
    //boxes me data hai usse boxno send karna hai with navigate
    navigate("/commondityEdit", { state: newItem });
  };
  const getUserData = async () => {
    const payloaddata = {
      subsid: selectedCustomer[0].subsid,
    };
    const res = await axios.post(
      `${baseUrl}/commodityorderlists/filterdata`,
      payloaddata
    );
    setData(res.data);
    dispatch({ type: "EDIT_OPT", data: false });
  };
  const getSubs = async () => {
    const result = await axios.get(`${baseUrl}/subscriber/all`);
    setSubs(result.data);
  };
  useEffect(() => {
    const result = subs.filter((item) =>
      item.companyname.toUpperCase().includes(txt.toUpperCase())
    );
    setSubsFilt(result);
  }, [TextTrackCue]);
  useEffect(() => {
    const result = subs.filter(
      (item) =>
        item.companyname.toUpperCase().includes(txt.toUpperCase()) ||
        `${item.mobile}`.includes(txt)
    );
    setSubsFilt(result);
  }, [txt]);
  useEffect(() => {
    selectedCustomer.length > 0 && getUserData();
  }, [selectedCustomer]);

  useEffect(() => {
    const tkn = localStorage.getItem("token");
    !tkn && navigate("/Login");
    getSubs();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedComd]);
  useEffect(() => {
    const filtered = data.filter((item) => item.qty !== item.dispatchqty);
    if (filtered.length === data.length) {
      setStatus("#FF8000");
    } else if (filtered.length === 0) {
      setStatus("#81B622");
    } else if (filtered.length !== data.length) {
      setStatus("#F8CF2C");
    }
  }, [data]);
  const handleRouteDispatch = () => {
    navigate("/dispatchDetails", { state: selectedCustomer });
  };
  return (
    <div className="container">
      <Grid spacing={2} container>
        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            className="content-status"
            style={{
              backgroundColor: `${status}`,
            }}
            onClick={status == "#81B622" && handleRouteDispatch}
          ></div>
        </Grid>
        {selectedCustomer.length === 0 ? (
          <>
            <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
              <TextField
                variant={"outlined"}
                fullWidth
                onChange={(e) => setTxt(e.target.value)}
              />
              <Grid container spacing={1}>
                {subsFilt &&
                  subsFilt.map((item, index) => (
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() =>
                          setSelectedCustomer([item]) & handleClr(index)
                        }
                      >
                        {item.mobile} - {item.companyname}
                      </Button>
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <Grid
              item
              xl={2}
              lg={2}
              md={2}
              sm={2}
              xs={2}
              onClick={handleNavigate}
            >
              <div className="content-plus">
                <SettingsIcon className="content-plus-icon" />
              </div>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xl={8} lg={7} md={8} sm={8} xs={7}>
              {selectedCustomer.length > 0 && (
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => setSelectedCustomer([])}
                >
                  {selectedCustomer[0].mobile} -{" "}
                  {selectedCustomer[0].companyname}
                </Button>
              )}
            </Grid>
            <Grid item xl={2} lg={2} md={2} sm={2} xs={2}
               onClick={handleNavigate}
            >
              <div href="" className="content-plus">
                <SettingsIcon className="content-plus-icon" />
              </div>
            </Grid>
          </>
        )}
        {boxes &&
          boxes.map((item) => (
            <React.Fragment>
              <Grid item xl={4} lg={7} md={12} sm={12} xs={8}>
                <Button
                  className="container"
                  variant="contained"
                  fullWidth
                  style={{ backgroundColor: "#2d3192", marginBottom: "10px" }}
                  // color="success"
                  onClick={() => getParkedData(item)}
                >
                  {item.boxno} | Qty - {item.qty}
                </Button>
              </Grid>
              <Grid item xl={4} lg={5} md={12} sm={12} xs={4}>
                <Button
                  className="container"
                  style={{
                    marginBottom: "10px",
                    border: "1px solid #2d3192",
                    color: "#2d3192",
                  }}
                  variant="outlined"
                  fullWidth
                  color="error"
                >
                  {item.allotmentinfo[0].parkbay}-{item.allotmentinfo[0].parkno}
                </Button>
              </Grid>
            </React.Fragment>
          ))}

        <Grid item xl={4} lg={12} md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            {/* <Grid item xl={4} lg={2.5} md={12} sm={12} xs={12}></Grid> */}

            <Grid
              className="container"
              item
              xl={4}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead style={{ backgroundColor: "#2d3192" }}>
                    <TableRow>
                      <TableCell className="content-list-table" align="center">
                        Comlstid
                      </TableCell>
                      <TableCell className="content-list-table" align="left">
                        Quantity
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((elem, index) => (
                        <CommodityItem
                          elem={elem}
                          ind={index}
                          handleComlstid={handleComlstid}
                          selectedComd={selectedComd}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default CommodityList;
